import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Text = styled.li`
  display: flex;
  align-items: center;
  justify-content: ${({ startPosition }) =>
    startPosition ? "flex-start" : "center"};
  font-style: normal;
  font-weight: ${({ fontWeight, fontSize }) =>
    fontWeight !== "" ? fontWeight : fontSize === "16px" ? "400" : "normal"};
  font-size: ${({ fontSize }) => (fontSize !== "" ? fontSize : "18px")};
  line-height: ${({ small }) => (small === "small" ? "16px" : "27px")};
  color: #111111;
  text-indent: 0px;
  @media (max-width: 475px) {
    font-size: 16px;
  }
`;

const Bullet = styled.span`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 5px;
  height: ${({ small }) => (small === "small" ? "16px" : "27px")};
  align-self: ${({ small }) => (small === "small" ? "center" : "flex-start")};
  margin-left: ${({ list }) => (list === "outside" ? "4px" : "0px")};
`;

function Lists({
  children,
  fontSize,
  list,
  fontWeight,
  small,
  startPosition,
  bullet,
}) {
  return (
    <Text
      fontSize={fontSize}
      fontWeight={fontWeight}
      small={small}
      startPosition={startPosition}
    >
      <Bullet list={list} small={small}>
        {bullet ? bullet : "•"}
      </Bullet>
      {children}
    </Text>
  );
}

Lists.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  list: PropTypes.string,
  startPosition: PropTypes.bool,
  small: PropTypes.string,
};

Lists.defaultProps = {
  fontSize: "",
  fontWeight: "",
  list: "",
  startPosition: false,
  small: "",
};

export default Lists;
