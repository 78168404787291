import React from "react";
import styled from "styled-components";
import PropTyes from "prop-types";
import Yes from "../../images/comparePageImages/heroImages/compare_tick.svg";
import No from "../../images/comparePageImages/heroImages/compare_x.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
`;

const NameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 26px;
  flex-grow: 1;
  box-sizing: border-box;
  width: 150px;
`;

const WriterFeature = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(53, 113, 206, 0.1);
  width: 345px;
  height: 60px;

  @media (max-width: 960px) {
    width: 180px;
  }

  @media (max-width: 500px) {
    width: 120px;
  }

  @media (max-width: 375px) {
    width: 60px;
  }
`;

const ProFeature = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  width: 345px;
  height: 60px;

  @media (max-width: 960px) {
    width: 180px;
  }

  @media (max-width: 500px) {
    width: 120px;
  }

  @media (max-width: 375px) {
    width: 60px;
  }
`;

const Text = styled.div`
  width: 100%;
  text-align: center;
  @media (max-width: 375px) {
    font-size: 10px;
  }
`;

function Feature({ name, writer, pro, text }) {
  return (
    <Container>
      <NameBox>{name}</NameBox>
      <WriterFeature>
        {writer && <Yes />}
        {!writer && <No />}
      </WriterFeature>
      <ProFeature>
        {pro && !text && <Yes />}
        {!pro && !text && <No />}
        {text && <Text>{text}</Text>}
      </ProFeature>
    </Container>
  );
}

Feature.propTypes = {
  name: PropTyes.string,
  writer: PropTyes.bool,
  pro: PropTyes.bool,
};

Feature.defaultProps = {
  name: "",
  writer: false,
  pro: false,
};

export default Feature;
