import React, { useContext } from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../shared/titles";
import { useIntl } from "gatsby-plugin-react-intl";
import CustomDownloadButton from "../shared/CustomDownloadButton";
import { whiteDownloadBtn } from "../../utils/CustomDownloadBtnStyles";
import { GlobalContext } from "../../Contexts/GlobalContext";

const Container = styled.div`
  display: grid;
  grid-area: 1/1;
  place-items: center;
  height: 360px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  //needs background because image background is transparent
  background: #3571ce;

  .background-image {
    //height is 360px because this components height is 360px and it doesn't change while width does
    height: 360px;
    width: 100%;
    z-index: 0;
    grid-area: 1/1;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 360px;
  gap: 42px;
  z-index: 1;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
`;

function Writer() {
  const intl = useIntl();
  const { setLinkType, isMac, setMacOpen } = useContext(GlobalContext);

  return (
    <Container>
      <StaticImage
        src="../../images/sharedImages/background_img.png"
        quality={95}
        formats={["AUTO", "WEBP", "AVIF", "PNG"]}
        alt="Ready Background Image"
        className="background-image"
      />
      <PositionContainer>
        <ContentHolder>
          <Row>
            <Title special={true}>
              {intl.formatMessage({
                id: "CompareCTA",
              })}
            </Title>
          </Row>
          <Row>
            {!isMac ? (
              <CustomDownloadButton
                title={intl.formatMessage({ id: "ToDownload" })}
                navigation="https://track.pdfpro10.com/product/normal/pdfcreator/trial"
                tracker="tracker"
                styles={whiteDownloadBtn}
              />
            ) : (
              <CustomDownloadButton
                title={intl.formatMessage({ id: "ToDownload" })}
                navigation={"/"}
                styles={whiteDownloadBtn}
                onClick={() => {
                  setMacOpen(true);
                  setLinkType("trial");
                }}
              />
            )}
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Writer;
