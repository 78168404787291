import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  height: max-content;
  font-style: normal;
  font-weight: ${({ special }) => (special ? "700" : "500")};
  font-size: ${({ special }) => (special ? "35px" : "36px")};
  line-height: ${({ special }) => (special ? "45px" : "40px")};
  color: ${({ special }) => (special ? "#ffffff" : "#111111")};
  margin: ${({ margin }) => margin};
  text-align: ${({ special }) => (special ? "center" : "intial")};
  @media (max-width: 960px) {
    text-align: ${({ special }) => (special ? "center" : "left")};
  }

  @media (max-width: 768px) {
    width: ${({ alignment }) => (alignment !== "" ? "100%" : "327px")};
    font-size: 28px;
    line-height: 36px;
    text-align: ${({ alignment, special }) =>
      alignment !== "" ? "center" : special ? "center" : "left"};
    width: 100%;
  }
`;

function Titles({ children, margin, width, alignment, special }) {
  return (
    <Title
      margin={margin}
      width={width}
      alignment={alignment}
      special={special}
    >
      {children}
    </Title>
  );
}

Titles.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
  alignment: PropTypes.string,
  special: PropTypes.bool,
};

Titles.defaultProps = {
  margin: "0px",
  width: "100%",
  alignment: "",
  special: false,
};

export default Titles;
