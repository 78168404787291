import React, { useContext } from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Title from "../shared/titles";
import Paragraph from "../shared/paragraphs";
import List from "../shared/customLists";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../shared/buttons";
import Dropdown from "./dropdown";
import Feature from "./feature";
import { useIntl } from "gatsby-plugin-react-intl";
import { GlobalContext } from "../../Contexts/GlobalContext";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 80px;

  .one {
    width: 193px;
  }
  .two {
    width: 193px;
  }

  .mobile-one {
    background: rgba(53, 113, 206, 0.1);
  }

  .mobile-two {
    background: #fafafa;
  }

  @media (max-width: 960px) {
    height: max-content;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  height: 100%;
  width: 100%;
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 28px;
  margin: -30px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    gap: 16px;
  }
`;

const ProductBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 345px;
  height: 473px;
  margin: 0px 0px;
  background: rgba(53, 113, 206, 0.1);

  &:nth-child(2) {
    background: #fafafa;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProductTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #111111;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 6px 0px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 295px;
  height: 275px;
  box-sizing: border-box;

  &:nth-child(2) {
    width: 265px;
  }
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 138px;
  margin-bottom: 16px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const MobileHolder = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: flex-end;
    align-items: flex-end;
    box-sizing: border-box;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
  background: rgba(174, 199, 236, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    height: 180px;
    width: 180px;
  }

  @media (max-width: 500px) {
    height: 120px;
    width: 120px;
  }

  @media (max-width: 375px) {
    height: 180px;
    width: 60px;
  }
`;

const MobileBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: bold;
  transform: rotate(-90deg);

  @media (max-width: 768px) {
    height: 180px;
    width: 180px;
  }

  @media (max-width: 500px) {
    height: 120px;
    width: 120px;
  }

  @media (max-width: 375px) {
    height: 180px;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
`;
function Hero() {
  const intl = useIntl();
  const lang = intl.formatMessage({
    id: "Lang",
  });
  const { isMac, setMacOpen, setLinkType } = useContext(GlobalContext);

  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Row>
            <TitleBox>
              <MainTitle features={true} instalHero={true}>
                {intl.formatMessage({
                  id: "CompareTitle",
                })}
              </MainTitle>
              <div>
                <p>
                  {intl.formatMessage({
                    id: "CompareParagraphOne",
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    id: "CompareParagraphTwo",
                  })}
                </p>
              </div>
            </TitleBox>
          </Row>
          <Row>
            <RowContainer>
              <ProductContainer>
                <ProductBox>
                  {/* <ImageContainer>
                    <StaticImage
                      src="../../images/comparePageImages/heroImages/pdfcreator-box.png"
                      quality={95}
                      formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                      alt="PDF Writer Product Box"
                    />
                  </ImageContainer> */}
                  <Box>
                    <ProductTitle>PDF CREATOR</ProductTitle>
                    <Paragraph type="center">
                      {intl.formatMessage({
                        id: "CompareCreatorTitle",
                      })}
                    </Paragraph>
                    <ListBox className="one">
                      <List fontSize="16px">
                        {intl.formatMessage({
                          id: "CompareCreatorSectionOne",
                        })}
                      </List>
                      <List fontSize="16px">
                        {intl.formatMessage({
                          id: "CompareCreatorSectionTwo",
                        })}
                      </List>
                      <List fontSize="16px">
                        {intl.formatMessage({
                          id: "CompareCreatorSectionThree",
                        })}
                      </List>
                    </ListBox>
                    {!isMac ? (
                      <Button
                        title={intl.formatMessage({
                          id: "CompareCreatorBuy",
                        })}
                        type="blue"
                        tracker="tracker"
                        navigation={intl.formatMessage({
                          id: "BuyLink",
                        })}
                        className
                      />
                    ) : (
                      <Button
                        title={intl.formatMessage({
                          id: "CompareCreatorBuy",
                        })}
                        type="blue"
                        navigation={false}
                        onClick={() => {
                          setMacOpen(true);
                          setLinkType("buy");
                        }}
                      />
                    )}
                  </Box>
                </ProductBox>
                <ProductBox>
                  {/* <StaticImage
                    src="../../images/comparePageImages/heroImages/pdf_product2.png"
                    height={74}
                    width={75}
                    quality={95}
                    style={{ transform: "scale(1.12)" }}
                    formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                    alt="PDF Pro Product Box"
                  /> */}
                  <Box>
                    <ProductTitle>PDF Pro</ProductTitle>
                    <Paragraph type="center">
                      {intl.formatMessage({
                        id: "CompareProTitle",
                      })}
                    </Paragraph>
                    <ListBox className="two">
                      <List fontSize="16px">
                        {intl.formatMessage({
                          id: "CompareProSectionOne",
                        })}
                      </List>
                      <List fontSize="16px">
                        {intl.formatMessage({
                          id: "CompareProSectionTwo",
                        })}
                      </List>
                      <List fontSize="16px">
                        {intl.formatMessage({
                          id: "CompareProSectionThree",
                        })}
                      </List>
                    </ListBox>
                    <Button
                      title={intl.formatMessage({
                        id: "CompareProVisit",
                      })}
                      navigation={`https://pdfpro.com?utm_source=pdfcreator.${lang}&utm_medium=referral`}
                      width="176px"
                      type="visit"
                      action="outbounds"
                    />
                  </Box>
                </ProductBox>
              </ProductContainer>
              <MobileHolder>
                <MobileContainer className="mobile-one">
                  <MobileBox>PDF CREATOR</MobileBox>
                </MobileContainer>
                <MobileContainer className="mobile-two">
                  <MobileBox>PDF PRO</MobileBox>
                </MobileContainer>
              </MobileHolder>
              <DropdownContainer>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionOneTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionOnePointOne",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionOnePointTwo",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionOnePointThree",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionOnePointFour",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionOnePointFive",
                    })}
                    writer={false}
                    pro={true}
                  />
                </Dropdown>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionTwoTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointOne",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointTwo",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointThree",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointFour",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointFive",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointSix",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointSeven",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointEight",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionTwoPointNine",
                    })}
                    writer={false}
                    pro={true}
                  />
                </Dropdown>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionThreeTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointOne",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointTwo",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointThree",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointFour",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointFive",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointSix",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionThreePointSeven",
                    })}
                    writer={false}
                    pro={true}
                  />
                </Dropdown>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionFourTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFourPointOne",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFourPointTwo",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFourPointThree",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFourPointFour",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFourPointFive",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFourPointSix",
                    })}
                    writer={false}
                    pro={true}
                  />
                </Dropdown>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionFiveTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFivePointOne",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFivePointTwo",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFivePointThree",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionFivePointFour",
                    })}
                    writer={false}
                    pro={true}
                  />
                </Dropdown>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionSixTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSixPointOne",
                    })}
                    writer={true}
                    pro={true}
                    text={intl.formatMessage({
                      id: "Subscription",
                    })}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSixPointTwo",
                    })}
                    writer={true}
                    pro={true}
                    text={intl.formatMessage({
                      id: "Subscription",
                    })}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSixPointThree",
                    })}
                    writer={true}
                    pro={true}
                  />
                </Dropdown>
                <Dropdown
                  title={intl.formatMessage({
                    id: "CompareSectionSevenTitle",
                  })}
                >
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSevenPointOne",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSevenPointTwo",
                    })}
                    writer={true}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSevenPointThree",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSevenPointFour",
                    })}
                    writer={false}
                    pro={true}
                  />
                  <Feature
                    name={intl.formatMessage({
                      id: "CompareSectionSevenPointFive",
                    })}
                    writer={false}
                    pro={true}
                  />
                </Dropdown>
              </DropdownContainer>
            </RowContainer>
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
