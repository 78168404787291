import React from "react";
import styled from "styled-components";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;
  background: #e0e0e0;
  cursor: pointer;
`;

const BoxTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #111111;
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 31px;
  height: 8px;
  width: 17px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  background: #ffffff;
`;

function Dropdown({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MainContainer
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      role="button"
    >
      <Container>
        <BoxTitle>{title}</BoxTitle>
        {isOpen && <ImgHolder>&#9650;</ImgHolder>}
        {!isOpen && <ImgHolder> &#9660;</ImgHolder>}
      </Container>
      {isOpen && children}
    </MainContainer>
  );
}

export default Dropdown;
